import { z } from 'zod';

const MessageAction = z.enum(['DOWNLOAD_FILE', 'SAVE_BASE64_DATA_TO_FILE']);

/**
 * The format of message input field for downloading a file
 */
export const downloadFileMessageInputSchema = z.object({
  // the URL of the file to download
  url: z.string(),
  // the filename to save the file as. If not provided, the filename will be
  // extracted from the URL or the server response headers
  filename: z.string().optional().nullable(),
  // the expected file extension of the file
  extension: z.string(),
  // additional headers to send with the request
  headers: z.record(z.string()).optional(),
});

/**
 * The format of message input field for saving base64 encoded data to a file
 * on the device
 */
export const saveBase64DataToFileMessageInputSchema = z.object({
  // the base64 encoded data to save
  data: z.string(),
  // the filename to save the file as
  filename: z.string().optional().nullable(),
  // the expected file extension of the file
  extension: z.string(),
});

/**
 * The message schema for the Web app to send messages to the React Native app
 * for processing
 */
export const messageSchema = z.discriminatedUnion('action', [
  z.object({
    action: z.literal('DOWNLOAD_FILE'),
    uuid: z.string(),
    from: z.literal('TIRECLOUD_RN_WEBVIEW'),
    input: downloadFileMessageInputSchema,
  }),
  z.object({
    action: z.literal('SAVE_BASE64_DATA_TO_FILE'),
    uuid: z.string(),
    from: z.literal('TIRECLOUD_RN_WEBVIEW'),
    input: saveBase64DataToFileMessageInputSchema,
  }),
]);

export const ipcResponseMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('WEBVIEW_IPC_SUCCESS'),
    uuid: z.string(),
    from: z.literal('TIRECLOUD_RN_NATIVE'),
    action: MessageAction,
    result: z.any(),
  }),
  z.object({
    type: z.literal('WEBVIEW_IPC_ERROR'),
    uuid: z.string(),
    from: z.literal('TIRECLOUD_RN_NATIVE'),
    action: MessageAction.optional().nullable(),
    error: z.any(),
  }),
]);
