import { Divider } from 'antd';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="tw-h-[50px] tw-bg-black tw-text-white">
      <div className="tw-px-[var(--spacing-4)] tw-h-full">
        <div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-py-4">
          <div className="version" dir="ltr">
            {import.meta.env.REACT_APP_VERSION.length > 0
              ? import.meta.env.REACT_APP_VERSION
              : import.meta.env.REACT_APP_PACKAGE_JSON_VERSION}
            {import.meta.env.REACT_APP_BRANCH ? `-${import.meta.env.REACT_APP_BRANCH}` : ''}
            {import.meta.env.REACT_APP_BUILD_NUMBER
              ? `#${import.meta.env.REACT_APP_BUILD_NUMBER}`
              : ''}
          </div>
          <div className='tw-inline-flex tw-items-center tw-gap-x-2'>
            <div>
              <Link to="/privacy-policy" className="tw-text-[var(--shade-400)] tw-text-[16px] hover:tw-text-[var(--shade-400)] tw-hidden md:tw-block">
                Privacy Policy
              </Link>
            </div>
            <Divider type="vertical" className="tw-border-[var(--shade-400)] tw-hidden md:tw-block" />
            <div className="copyright tw-text-[16px]">
              <span>&copy; Copyright</span> <span>{new Date().getFullYear()}</span>{' '}
              <span>TireCloud.One</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
