const env = import.meta.env ?? process.env;

export default {
  [`/orders/export`]: '/orderExport', // done
  '/call-offs/export': '/callOffExport', // done
  '/call-offs/notify': '/notifyCallOffViaEmail/', // done
  '/tire-sizes/filter': '/filtersearch/', // done
  '/tire-sizes/filter-panel': '/filterdata/', // done
  '/tire-sizes/user-complaint': '/productsize/', // done
  '/notices': '/getnoticelist/',
  '/tire-size/stock-notify': '/notifyme/',
  [`${env.REACT_APP_API_URL}/api/v1/tire-sizes/stock`]: `${env.REACT_APP_IMPORT_URL}/stock`, // done
  '/orders/notify': '/notifyorderviaemail/', // done
  '/users/verify': '/verifyuser/', // done
  '/users/change-password': '/changepassword/', // done
  '/users/shipping-addresses': '/latestshippingaddress/', // done
  '/users/reset-password': '/reset-password',
  '/users/feedback-questions': '/feedbackquestion/',
  '/users/export-user-answers': '/admin/export-user-answers/',
  '/users/export-user-complaints': '/userComplaintExport/',
  '/users/update-email': '/updateUserEmail/',
  '/users/update-phone-number': '/updateUserPhoneNumber/',
  '/users/set-username-password': '/set-username-password',
  '/users/set-password': '/set-password',
  '/users/register': '/register',
  '/vouchers/verify': '/verifyVoucher/',
  '/vouchers': '/getVoucherPromotion/',
  '/vouchers/validate-users': '/validateUserForVoucher/',
  '/vouchers/users': '/userVouchers',
};
