import { Link } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import css from './403.module.scss';

function Error403Page() {
  return (
    <main className="page-content">
      <div className={css['access-denied']}>
        <div className={css.inner}>
          <h1>
            4<span>0</span>3
          </h1>
          <h3>
            You don&apos;t have permission to access this page. Please contact your administrator.
          </h3>
          <Link to="/">
            <Button>Go back to Home Page</Button>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Error403Page;
