import { usePageContentsQuery } from '@/utils/custom-page';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import { Loader } from 'tirecloud-pattern-library/dist/components/Loader/Loader';
import { Modal } from 'tirecloud-pattern-library/dist/components/modal/Modal';

export default function ContactUsModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { data, isFetching } = usePageContentsQuery();

  return (
    <Modal open={open} onCancel={onClose} footer={null} className='contact-us-modal'>
      {isFetching ? (
        <Loader fullScreen />
      ) : (
        <div className="tw-text-center">
          <h1 className="tw-text-2xl tw-font-bold">{data?.contactUs.title}</h1>
          <h2 className="tw-text-2xl tw-font-bold">{data?.contactUs.subTitle}</h2>
          <div className="tw-text-md tw-mb-12">
            <p dangerouslySetInnerHTML={{ __html: data?.contactUs.message ?? '' }}></p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-y-4">
            <div className="tw-flex tw-items-center tw-justify-center phone-number-display">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <defs></defs>
                <path className="tw-fill-none" d="M0,0H24V24H0Z" />
                <path d="M21,15.46l-5.27-.61-2.52,2.52a15.045,15.045,0,0,1-6.59-6.59L9.15,8.25,8.54,3H3.03A17,17,0,0,0,21,20.97V15.46Z" />
              </svg>
              &nbsp;&nbsp;&nbsp;
              <span>{data?.contactUs.contactNumber}</span>
            </div>
            <div>Or</div>
            <div>
              <Button className="tw-min-w-[200px]">
                <a href={`tel:${data?.contactUs.contactNumber}`}>Call Now</a>
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
