import { ProductBundle, SpecialPrice, Tire, TireDot, TireSize } from '@/app/products/types';
import { customerSpecificRouteMapping } from '@/customer-specific-configs/customer-specific-settings';
import { useAppSelector } from '@/stores';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { App } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { useAxios } from './axios';
import { useAdminSettingQuery } from './misc';
import { STOCK_LOW_THRESHOLD } from '@/constants';

export interface CartItemResponse {
  id: number;
  tireSize: TireSize;
  tireDot: TireDot;
  quantity: number;
  specialPrice: SpecialPrice | null;
  productBundleItem:
    | (Pick<
        ProductBundle['productBundleItem'][number],
        'id' | 'quantity' | 'qPrice' | 'price' | 'tireSize' | 'yearOfManufacture'
      > & {
        productBundle: Pick<ProductBundle, 'id' | 'modelName'>;
      })
    | null;
}

export interface CartResponse {
  cartItem: CartItemResponse[];
  id: number;
  totalAmount: number;
}

export function getPriceImpl(
  specialPrices: SpecialPrice[],
  basePrice: number,
  count: number
):
  | {
      specialPriceId: number;
      specialPrice: SpecialPrice;
      price: number;
      originalPrice: number;
    }
  | {
      specialPriceId: null;
      specialPrice: SpecialPrice | null;
      price: number;
      originalPrice: null;
    } {
  const maybeValidSpecialPrice = specialPrices.filter((specialPrice) => {
    const validFrom =
      typeof specialPrice.validFrom === 'string'
        ? dayjs(specialPrice.validFrom)
        : dayjs.unix(specialPrice.validFrom);
    const validTo =
      typeof specialPrice.validTo === 'string'
        ? dayjs(specialPrice.validTo)
        : dayjs.unix(specialPrice.validTo);

    if (validFrom <= dayjs() && validTo >= dayjs()) {
      return true;
    }
    return false;
  });

  if (maybeValidSpecialPrice.length === 0)
    return {
      specialPriceId: null,
      specialPrice: null,
      price: basePrice,
      originalPrice: null,
    };

  const validSpecialPrice = maybeValidSpecialPrice.find((specialPrice) => {
    return count >= specialPrice.minPurchaseQuantity || specialPrice.minPurchaseQuantity <= 1;
  });

  if (validSpecialPrice)
    return {
      specialPriceId: validSpecialPrice.id,
      specialPrice: validSpecialPrice,
      price: validSpecialPrice.specialPrice,
      originalPrice: basePrice,
    };

  return {
    specialPriceId: null,
    specialPrice: maybeValidSpecialPrice[0],
    price: basePrice,
    originalPrice: null,
  };
}

export function getTireDotPrice(
  tireDot: TireDot[],
  id: number,
  count: number
):
  | {
      specialPriceId: number;
      specialPrice: SpecialPrice;
      price: number;
      originalPrice: number;
    }
  | {
      specialPriceId: null;
      specialPrice: SpecialPrice | null;
      price: number;
      originalPrice: null;
    } {
  const selectedDot = tireDot.find((dot) => dot.id === id);
  if (!selectedDot)
    return { specialPriceId: null, specialPrice: null, price: 0, originalPrice: null };

  return getPriceImpl(selectedDot.specialPrice, selectedDot.price, count);
}

export function useIsProductOutOfStock() {
  const { data: adminSettings } = useAdminSettingQuery();

  return useCallback(
    (item: TireSize | ProductBundle) => {
      // product bundle is never out of stock
      if (item.isBundle) return false;

      return adminSettings?.featureFlags.page.product.enableTireDot === true
        ? item.tireDot.length === 0
        : item.tireDot[0].stock === 0;
    },
    [adminSettings?.featureFlags.page.product.enableTireDot]
  );
}

export function useBrandsQuery() {
  const token = useAppSelector((state) => state.auth.token);
  const axios = useAxios();

  return useQuery<Array<{ id: number; brandName: string }>>({
    queryKey: ['brands'],
    queryFn: async () => {
      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/brands`)
        .then((res) => res.data)
        .then((res) => res.brands)
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    enabled: !!token,
  });
}

export function useSegmentsQuery() {
  const token = useAppSelector((state) => state.auth.token);
  const axios = useAxios();

  return useQuery<Array<{ id: number; name: string }>>({
    queryKey: ['segments'],
    queryFn: async () => {
      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/segments`)
        .then((res) => res.data)
        .then((res) => res.segments)
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    enabled: !!token,
  });
}

export function useProductSizesQuery(brand: string, segment: string) {
  const token = useAppSelector((state) => state.auth.token);
  const { i18n } = useTranslation();
  const axios = useAxios();

  return useQuery<
    Array<Omit<TireSize, 'tire' | 'tireDot'> & Pick<Tire, 'title' | 'ssrMark' | 'frFlag'>>
  >({
    queryKey: ['productSizes', brand, segment],
    queryFn: async () => {
      return axios
        .get(
          `${import.meta.env.REACT_APP_API_URL}${customerSpecificRouteMapping['/tire-sizes/user-complaint']}?brand=${brand}&segment=${segment}&lang=${
            i18n.language
          }`
        )
        .then((res) => res.data)
        .then((res) => {
          return res;
        })
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    enabled: !!token && brand.length > 0 && segment.length > 0,
  });
}

export function useRecentViewdProductQuery() {
  const { user } = useAppSelector((state) => state.auth);
  const { i18n } = useTranslation();
  const { data: adminSettings } = useAdminSettingQuery();
  const axios = useAxios();

  return useQuery<
    Array<{
      id: number;
      tireSize: TireSize;
    }>
  >({
    queryKey: [
      'recentViewedProduct',
      i18n.language,
      user?.id,
      adminSettings?.featureFlags.page.product.enableTireDot,
    ],
    queryFn: async () => {
      return axios
        .get(
          `${import.meta.env.REACT_APP_API_URL}/api/v1/recent-views?userId=${user?.id}&limit=30&offset=0`
        )
        .then((res) => res.data)
        .then((res) => {
          const data = (
            res as {
              recentViews: Array<{
                id: number;
                tireSize: Array<
                  Omit<TireSize, 'tire'> & {
                    tire: Tire[];
                  }
                >;
              }>;
            }
          ).recentViews.map((item) => {
            return {
              id: item.id,
              tireSize: {
                ...item.tireSize[0],
                tire: item.tireSize[0].tire[0],
              },
            };
          });
          return data;
        })
        .then((items) => {
          const data = items.map((item) => {
            if (adminSettings?.featureFlags.page.product.enableTireDot === true) {
              return {
                ...item,
                tireSize: {
                  ...item.tireSize,
                  tireDot: item.tireSize.tireDot.filter((dot) => dot.stock > 0),
                  isBundle: false,
                } as TireSize,
              };
            } else {
              return {
                ...item,
                tireSize: {
                  ...item.tireSize,
                  tireDot: [
                    {
                      id: item.tireSize.id,
                      dotCode: null,
                      yearOfManufacture: new Date().getFullYear(),
                      isDefault: true,
                      stock: item.tireSize.stock,
                      price: item.tireSize.price,
                      specialPrice: item.tireSize.specialPrice,
                    } as TireDot,
                  ],
                  isBundle: false,
                } as TireSize,
              };
            }
          });
          return data;
        })
        .catch((error) => {
          Sentry.captureException(error);
          return [];
        });
    },
  });
}

export function useFeaturedProductsQuery() {
  const { i18n } = useTranslation();
  const axios = useAxios();

  return useQuery<TireSize[]>({
    queryKey: ['featuredProducts'],
    queryFn: async () => {
      const currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss');

      return axios
        .get(
          `${
            import.meta.env.REACT_APP_API_URL
          }/api/v1/tire-sizes/featured?showasFeaturedProduct=1&validFrom=${currentDate}&_operatorValidFrom=<=&validUntil=${currentDate}&_operatorValidUntil=>=&lang=${
            i18n.language
          }`
        )
        .then((res) => res.data)
        .then((res) =>
          res.tireSizes.map(
            (
              item: Omit<TireSize, 'tire'> & {
                tire: Tire[];
              }
            ) => {
              return {
                ...item,
                tire: item.tire[0],
              };
            }
          )
        )
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
  });
}

export function useCreateProductStockNotify() {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const { notification: notiAPI } = App.useApp();
  const axios = useAxios();

  return useCallback(
    (tireSizeId: number) => {
      axios({
        method: match(import.meta.env.REACT_APP_CLIENT_NAME)
          .with('kislev', () => 'GET')
          .otherwise(() => 'POST'),
        url: match(import.meta.env.REACT_APP_CLIENT_NAME)
          .with(
            'kislev',
            () =>
              `${customerSpecificRouteMapping['/tire-size/stock-notify']}?tireSizeId=${tireSizeId}&userId=${user?.id}`
          )
          .otherwise(
            () => `${customerSpecificRouteMapping['/tire-size/stock-notify']}/${tireSizeId}`
          ),
      }).then(() => {
        notiAPI.success({
          message: t('component.addToCartBtn.notifySuccess'),
        });
      });
    },
    [axios, notiAPI, t, user?.id]
  );
}

export function useCreateRecentViewedProduct() {
  const axios = useAxios();

  return useCallback(
    (tireSizeId: number) => {
      axios.post(`${import.meta.env.REACT_APP_API_URL}/api/v1/recent-views`, {
        tireSize: tireSizeId,
      });
    },
    [axios]
  );
}

export function useProductDetailsQuery(
  tireSizeId: string | number | undefined,
  productCode: string | undefined
) {
  const { data: adminSettings } = useAdminSettingQuery();
  const { i18n } = useTranslation();
  const axios = useAxios();

  return useQuery<TireSize>({
    queryKey: ['productDetails', tireSizeId, productCode, i18n.language],
    enabled: !!tireSizeId || !!productCode,
    queryFn: async () => {
      return axios
        .get(
          tireSizeId
            ? `/api/v1/tire-size/${tireSizeId}`
            : productCode
              ? `/api/v1/tire-size/code/${productCode}`
              : ''
        )
        .then((res) => res.data)
        .then((res) => {
          if (adminSettings?.featureFlags.page.product.enableTireDot)
            return {
              ...res.tireSize,
              tire: res.tireSize.tire[0],
              tireDot: res.tireSize.tireDot.filter((item: TireDot) => item.stock > 0),
            } as TireSize;

          return {
            ...res.tireSize,
            tire: res.tireSize.tire[0],
            tireDot: [
              {
                id: res.tireSize.id,
                dotCode: null,
                yearOfManufacture: new Date().getFullYear(),
                isDefault: true,
                stock: res.tireSize.stock,
                price: res.tireSize.price,
                specialPrice: res.tireSize.specialPrice,
              },
            ] as TireDot[],
          };
        })
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
  });
}

export function useManyProductDetailsQuery(tireSizeIds: Array<string | number> | undefined) {
  const token = useAppSelector((state) => state.auth.token);
  const axios = useAxios();

  return useQuery({
    queryKey: ['productDetails', ...(tireSizeIds ?? [])],
    enabled: token !== null && tireSizeIds && tireSizeIds.length > 0,
    queryFn: async () => {
      return Promise.all(
        (tireSizeIds ?? []).map((tireSizeId) => {
          return axios
            .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/tire-size/${tireSizeId}`)
            .then((res) => res.data)
            .then((res) => {
              return {
                ...res.tireSize,
                tire: res.tireSize.tire[0],
                tireDot: res.tireSize.tireDot.filter((item: TireDot) => item.stock > 0),
              } as TireSize;
            })
            .catch((error) => {
              Sentry.captureException(error);
              return Promise.reject(error);
            });
        })
      );
    },
  });
}

export function getStockStatus(stock: number) {
  return stock > STOCK_LOW_THRESHOLD ? 'available' : stock === 0 ? 'out' : 'low';
}
