import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'tirecloud-pattern-library/dist/components/modal/Modal';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { APP_UPDATE_MODAL_REMIND_ME_LATER_KEY } from './constants';
import { Button } from 'tirecloud-pattern-library';

const AppUpdateModal = () => {
  const { t } = useTranslation();

  const {
    offlineReady: [, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      console.info('SW Registered: ' + r?.active?.scriptURL);
    },
    onRegisterError(error) {
      console.error('SW registration error: ', error);
      Sentry.captureException(error);
    },
  });

  const close = useCallback(() => {
    setOfflineReady(false);
    setNeedRefresh(false);
    localStorage.setItem(
      APP_UPDATE_MODAL_REMIND_ME_LATER_KEY,
      dayjs().add(1, 'minute').unix().toString()
    );
  }, [setNeedRefresh, setOfflineReady]);

  const shouldShow = useMemo(() => {
    const reminderMeLaterTime = localStorage.getItem(APP_UPDATE_MODAL_REMIND_ME_LATER_KEY);
    return (
      needRefresh &&
      (!reminderMeLaterTime || dayjs().isAfter(dayjs.unix(parseInt(reminderMeLaterTime))))
    );
  }, [needRefresh]);

  return (
    <>
      <Modal
        open={shouldShow}
        title={t('component.appUpdateModal.title')}
        centered
        onCancel={close}
        footer={
          <div
            key="footer-buttons"
            className="tw-flex tw-justify-between tw-items-center tw-gap-x-2"
          >
            <div className="tw-flex tw-items-center tw-gap-x-2">
              <Button
                key="cancel"
                color="secondary"
                onClick={() => {
                  close();
                }}
              >
                {t('component.appUpdateModal.remindMeLater')}
              </Button>
            </div>
            <Button
              key="ok"
              onClick={() => {
                try {
                  updateServiceWorker(true)
                    .then(() => {
                      window.location.reload();
                    })
                    .catch((error) => {
                      console.error('SW update error: ', error);
                      Sentry.captureException(error);
                      window.location.reload();
                    });
                } catch (error) {
                  console.log('Something wrong while calling updateServiceWorker function', error);
                  Sentry.captureException(error);
                  window.location.reload();
                }
              }}
            >
              {t('component.appUpdateModal.updateNow')}
            </Button>
          </div>
        }
      >
        {t('component.appUpdateModal.description')}
      </Modal>
    </>
  );
};

export default AppUpdateModal;
