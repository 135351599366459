import { createSlice } from '@reduxjs/toolkit';
import { Location } from 'react-router-dom';

const routerHistoryStateSlice = createSlice({
  name: 'routerHistory',
  initialState: {
    history: [],
  } as {
    history: Array<Location>;
  },
  reducers: {
    addHistory(state, action: { payload: Location }) {
      state.history.push(action.payload);
    },
    popHistory(state) {
      state.history.pop();
    },
    removeNHistory(state, action: { payload: number }) {
      if (action.payload > 0) state.history.splice(-action.payload);
    },
    replaceHistory(state, action: { payload: Location }) {
      state.history.pop();
      state.history.push(action.payload);
    },
    clearHistory(state) {
      state.history = [];
    },
  },
});

export default routerHistoryStateSlice;
