import { COOKIE_CONSENT_KEY } from '@/constants';
import { cookiesUsedInTheApp } from '@/utils/misc';
// import { useUpdateUserMutation } from '@/utils/user';
import { Checkbox, Divider, Switch, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion } from 'tirecloud-pattern-library/dist/components/accordion/Accordion';
import { AccordionDetails } from 'tirecloud-pattern-library/dist/components/accordion/_inner/AccordionDetails';
import { AccordionHeading } from 'tirecloud-pattern-library/dist/components/accordion/_inner/AccordionHeading';
import { AccordionItem } from 'tirecloud-pattern-library/dist/components/accordion/_inner/AccordionItem';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import { Modal } from 'tirecloud-pattern-library/dist/components/modal/Modal';
import isEqual from 'lodash/isEqual';
import { match } from 'ts-pattern';
import kislevPrivacyPolicy from '@/customer-specific-configs/kislev/privacy-policy-22-03-23.pdf?url';

function CookieConsentModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { t } = useTranslation();
  const [isAccepted, setIsAccepted] = useState(false);
  const [hasSavedConsent, setHasSavedConsent] = useState(false);
  // const updateUser = useUpdateUserMutation();

  const [originalConsentSettings, setOriginalConsentSettings] = useState<{
    [key: string]: boolean;
  }>({});
  const [consentSettings, setConsentSettings] = useState<{
    [key: string]: boolean;
  }>({});

  const setDefaultConsentSettings = useCallback(() => {
    const cookieSettings: { [key: string]: boolean } = {};
    for (const cookieGroup of cookiesUsedInTheApp) {
      for (const subCookie of cookieGroup.cookies) {
        if (subCookie.required) {
          cookieSettings[subCookie.id] = true;
        } else {
          cookieSettings[subCookie.id] = subCookie.defaultValue ?? true;
        }
      }
    }
    setConsentSettings(cookieSettings);
    setOriginalConsentSettings(cookieSettings);
  }, []);

  const loadConsentSettings = useCallback(() => {
    const savedConsentSettings = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (savedConsentSettings) {
      try {
        const parsedSettings = JSON.parse(savedConsentSettings);
        const cookieSettings: { [key: string]: boolean } = {};

        for (const cookieGroup of cookiesUsedInTheApp) {
          for (const subCookie of cookieGroup.cookies) {
            if (subCookie.required) {
              cookieSettings[subCookie.id] = true;
            } else if (typeof parsedSettings[subCookie.id] === 'boolean') {
              cookieSettings[subCookie.id] = parsedSettings[subCookie.id];
            } else {
              cookieSettings[subCookie.id] = subCookie.defaultValue ?? true;
            }
          }
        }

        setConsentSettings(cookieSettings);
        setOriginalConsentSettings(cookieSettings);
        setHasSavedConsent(true);
      } catch (e) {
        setDefaultConsentSettings();
      }
    } else {
      setDefaultConsentSettings();
    }
  }, [setDefaultConsentSettings]);

  useEffect(() => {
    loadConsentSettings();
  }, [loadConsentSettings]);

  return (
    <Modal
      footer={null}
      closable={hasSavedConsent}
      open={isOpen}
      onCancel={hasSavedConsent ? onClose : undefined}
    >
      <div id="cookie-consent-modal-content">
        <h1 className="tw-text-2xl tw-mb-4">{t('component.trackingConsentModal.title')}</h1>
        <Accordion>
          {cookiesUsedInTheApp.map((cookie) => {
            return (
              <AccordionItem key={cookie.id} open={cookie.id === 'essential'}>
                <AccordionHeading>
                  <div>
                    <h3 className="tw-text-xl">
                      {t(`component.trackingConsentModal.cookieDescriptions.${cookie.id}.name`)}
                    </h3>
                    {/* <p className='tw-text-sm tw-font-normal'>{cookie.description}</p> */}
                  </div>
                </AccordionHeading>
                <AccordionDetails>
                  {cookie.cookies.map((subCookie, index, array) => {
                    return (
                      <>
                        <div key={subCookie.id}>
                          <div className="tw-flex tw-items-center tw-justify-between">
                            <h4 className="tw-text-lg">
                              {t(
                                `component.trackingConsentModal.cookieDescriptions.${cookie.id}.${subCookie.id}.name`
                              )}
                            </h4>
                            <Switch
                              id={`cookie-switch-${cookie.id}-${subCookie.id}`}
                              disabled={subCookie.required}
                              defaultChecked={
                                subCookie.required ||
                                (consentSettings[subCookie.id] ?? subCookie.defaultValue ?? true)
                              }
                              onChange={(v) => {
                                setConsentSettings((prev) => {
                                  return {
                                    ...prev,
                                    [subCookie.id]: v,
                                  };
                                });
                              }}
                            />
                          </div>
                          <p className="tw-text-sm tw-font-normal">
                            {t(
                              `component.trackingConsentModal.cookieDescriptions.${cookie.id}.${subCookie.id}.description`
                            )}
                          </p>
                          {subCookie.cookie_names.length > 0 && (
                            <p className="tw-text-sm tw-font-normal">
                              <span>Cookies: </span><span>{subCookie.cookie_names?.join(', ')}</span>
                            </p>
                          )}
                        </div>
                        {index !== array.length - 1 && <Divider />}
                      </>
                    );
                  })}
                </AccordionDetails>
              </AccordionItem>
            );
          })}
        </Accordion>
        {!hasSavedConsent && (
          <Checkbox
            checked={isAccepted}
            onChange={(v) => {
              setIsAccepted(v.target.checked);
            }}
            id="cookie-consent-policy-accept-checkbox"
          >
            <Trans
              i18nKey="component.trackingConsentModal.question"
              components={{
                linkElem: match(import.meta.env.REACT_APP_CLIENT_NAME)
                  .with('kislev', () => (
                    <a
                      className="tw-font-bold tw-underline"
                      href={kislevPrivacyPolicy}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ))
                  .otherwise(() => <b />),
              }}
            />
          </Checkbox>
        )}
        <div className="tw-mt-6 ltr:tw-text-right rtl:tw-text-left">
          <Tooltip
            title={
              hasSavedConsent
                ? ''
                : !isAccepted
                  ? t('component.trackingConsentModal.pleaseAcceptToContinue')
                  : ''
            }
            placement="top"
            arrow
          >
            <div className="tw-inline-block">
              <Button
                id="cookie-consent-accept-button"
                onClick={() => {
                  // updateUser.mutate({ id: user?.id ?? '', trackingConsent: true });
                  if (hasSavedConsent || isAccepted) {
                    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(consentSettings));
                    loadConsentSettings();
                    onClose();
                  }
                }}
                disabled={
                  hasSavedConsent ? isEqual(consentSettings, originalConsentSettings) : !isAccepted
                }
              >
                {t('component.trackingConsentModal.accept')}
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
}

export default CookieConsentModal;
