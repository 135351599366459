import Error403Page from '@/app/errors/403';
import { useAppSelector } from '@/stores';
import { ALL_POSSIBLE_USER_ROLES } from '@/stores/states/auth';
import cloneDeep from 'lodash/cloneDeep';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from 'tirecloud-pattern-library/dist/components/Loader/Loader';
import { match } from 'ts-pattern';

export interface UserCheckProps {
  requiredRoles: readonly (typeof ALL_POSSIBLE_USER_ROLES)[number][];
}

function RoleGuard({ children, requiredRoles }: PropsWithChildren & UserCheckProps) {
  const navigate = useNavigate();
  const authState = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [roleCheckStatus, setRoleCheckStatus] = useState<'403' | 'success' | 'loading'>('loading');

  const doRoleCheck = useCallback((): '403' | 'success' | 'loading' => {
    const { user, initialized } = authState;
    if (!initialized) {
      return 'loading';
    }

    const loginPageURL = '/auth/login';

    if (location.pathname === loginPageURL && searchParams.get('error') === 'unauthorized') {
      return 'success';
    }

    // this is run since user logged out, in this case we should redirect to login page
    if (searchParams.get('action') === 'logout') {
      navigate(loginPageURL, {
        replace: true,
      });
      return 'success';
    }

    // user role is satisfied
    if (user && requiredRoles.includes(user.__role)) {
      return 'success';
    }
    // user is not logged in but doesn't have the required role
    if (requiredRoles.includes('GUEST') && !user) {
      return 'success';
    }

    // user is logged in, role is not satisfied and the page also requires a role
    const redirectURLForLoginPage = `${location.pathname}${location.search}${location.hash}`;
    if (!requiredRoles.includes('GUEST') && user !== null) {
      return '403';
    }

    // user is not logged in and the page requires a role
    if (user === null && !requiredRoles.includes('GUEST')) {
      navigate(`${loginPageURL}?redirect=${encodeURIComponent(redirectURLForLoginPage)}`, {
        replace: true,
      });
      return 'success';
    }

    // if it's login URL, try to follow redirect URL
    if (user !== null && location.pathname === loginPageURL) {
      const redirect = searchParams.get('redirect');
      if (redirect) {
        const redirectURL = decodeURIComponent(redirect);
        if (!redirectURL.startsWith(loginPageURL)) {
          navigate(redirectURL, {
            replace: true,
          });
          return 'success';
        }
      }
    }

    // user is logged in, role is not satisfied and the page doesn't require a role
    if (user !== null) {
      navigate('/', {
        replace: true,
      });
      return 'success';
    }

    return 'loading';
  }, [
    authState,
    location.hash,
    location.pathname,
    location.search,
    navigate,
    requiredRoles,
    searchParams,
  ]);

  useEffect(() => {
    const result = doRoleCheck();
    setRoleCheckStatus(result);
  }, [doRoleCheck]);

  if (!authState.initialized) return <Loader fullScreen />;
  return match(roleCheckStatus)
    .with('403', () => <Error403Page />)
    .with('success', () => children)
    .otherwise(() => <Loader fullScreen />);
}

export default RoleGuard;
