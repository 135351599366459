import { Link, useRouteError } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import css from './index.module.scss';

function GenericErrorElement() {
  const error = useRouteError();

  return (
    <main className="page-content">
      <div className={css['error']}>
        <div className={css.inner}>
          <h1>
            5<span>0</span>0
          </h1>
          <h3>Something went wrong while loading the page. Please try again later.</h3>
          {error !== undefined && error !== null ? (
            <p className="tw-mb-8">{error.toString()}</p>
          ) : null}
          <Link to={'/products'}>
            <Button
              onClick={() => {
                window.location.reload();
              }}
            >
              {'Reload'}
            </Button>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default GenericErrorElement;
