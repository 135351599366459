import { useAppSelector } from '@/stores';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { customerSpecificRouteMapping } from '@/customer-specific-configs/customer-specific-settings';
import { useAxios } from './axios';

export interface Notification {
  id: number;
  isNew: number;
  creationDate: number;
  description: string;
  notifyRegarding: string;
  title: string;
  link?: string;
}

export function useNotificationQuery(
  {
    order = 'creationDate',
    orderkey = 'desc',
    offset = 0,
    limit = 15,
  }: {
    order?: string;
    orderkey?: 'asc' | 'desc';
    offset?: number;
    limit?: number;
  },
  options: UseQueryOptions<Array<Notification>> = {}
) {
  const { token, user } = useAppSelector((state) => state.auth);
  const { i18n } = useTranslation();
  const axios = useAxios();

  return useQuery<Array<Notification>>({
    queryKey: ['notification', order, orderkey, offset, limit, i18n.language],
    queryFn: async () => {
      return axios
        .get(
          `${import.meta.env.REACT_APP_API_URL}${customerSpecificRouteMapping['/notices']}?${new URLSearchParams(
            {
              userId: user?.id ? user.id.toString() : '',
              lang: i18n.language,
              limit: limit.toString(),
              offset: offset.toString(),
              order,
              orderkey,
            }
          ).toString()}`
        )
        .then((res) => res.data)
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    enabled: !!token,
    ...options,
  });
}

export function useMarkNotificationAsReadMutation() {
  const client = useQueryClient();
  const axios = useAxios();

  return useMutation({
    mutationKey: ['markNotificationAsRead'],
    mutationFn: async (id: number) => {
      return axios
        .patch(`${import.meta.env.REACT_APP_API_URL}/api/v1/notice/${id}`, {
          isNew: false,
        })
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    onSuccess: () => {
      client.invalidateQueries(['notification']);
    },
  });
}
