import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import browserLanguageDetector from 'i18next-browser-languagedetector';
import { SETTING_LANGUAGE_KEY, SUPPORTED_LANGUAGES } from './constants';

const supportedLngsKeys = Object.keys(SUPPORTED_LANGUAGES);

i18n
  .use(initReactI18next)
  .use(browserLanguageDetector)
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      return import(`./locales/${language}/${namespace}.json`).then((data) => {
        return data;
      });
    })
  )
  .init({
    supportedLngs: supportedLngsKeys,
    fallbackLng: supportedLngsKeys[0],
    preload: [supportedLngsKeys[0]],
    interpolation: { escapeValue: false },
    react: {
      transWrapTextNodes: 'span'
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: SETTING_LANGUAGE_KEY,
    },
  });

export default i18n;
