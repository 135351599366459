const env = import.meta.env ?? process.env;

const baseFeatureFlags = {
  page: {
    mockup: {
      enabled: true,
    },
    product: {
      enableVoucherSlider: env.REACT_APP_BRANCH !== 'main',
      enableSaleTargetBanner: true,
      enableFeaturedProduct: true,
      enableOnboarding: true,
      enableTireDot: true,
      enableUseInfinityScroll: false,
      enableDirectInquiry: true,
      enableOEAttributeValueBox: false,
      enableProductBundle: true,
    },
    dashboard: {
      enableVoucherSlider: env.REACT_APP_BRANCH !== 'main',
      useExperimentalDashboardSetting: true,
    },
    cart: {
      enableVoucherSelector: env.REACT_APP_BRANCH !== 'main',
    },
    misc: {
      enableCustomPage: true,
      enableRefreshToken: false,
    },
    admin: {
      analytics: {
        enabled: true,
      },
      userManagement: {
        enabled: true,
      },
      dashboardSettings: {
        enabled: true,
      },
      emailSettings: {
        enabled: true,
      },
    },
  },
  component: {
    header: {
      enableLogoThemeVariant: false,
    },
    voucher: {
      enableProductFilter: env.REACT_APP_BRANCH !== 'main',
    },
    checkout: {
      enableCheckoutStepsInProduct: true,
      enableDeliveryOption: env.REACT_APP_BRANCH !== 'main',
    },
  },
};

export type BaseFeatureFlags = typeof baseFeatureFlags;
export default baseFeatureFlags;
