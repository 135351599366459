import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface UserUpdatePayload {
  id: string;
  username?: string;
  userNumber?: string;
  fullName?: string;
  jobTitle?: string | null;
  Company?: string;
  email?: string;
  phoneNumber?: string;
  defaultCommunication?: 'Email' | 'SMS';
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipcode?: string | null;
  country?: string | null;
  trackingConsent?: boolean;
}

export const ALL_POSSIBLE_USER_ROLES = ['ROLE_WEBSHOP_USER', 'GUEST', 'ROLE_ADMIN'] as const;

export interface WebshopUser {
  __role: (typeof ALL_POSSIBLE_USER_ROLES)[number];
  id: string;
  username: string;
  userNumber: string;
  published: boolean;
  about: {
    fullName: string;
    jobTitle: string | null;
    company: string;
  };
  contactInfo: {
    email: string;
    phoneNumber: string;
    defaultCommunication: 'Email' | 'SMS';
  };
  shippingAddress: {
    address: string | null;
    city: string | null;
    state: string | null;
    zipCode: string | null;
    country: string | null;
  };
  credit: {
    currencyType: 'EURO' | 'ILS' | 'USD';
    lineObligo: number;
    utilizedObligo: number;
    unutilizedObligo: number;
    deferredChequeTotal: number;
    openOrderValue: number;
    openDebt: number;
    unbilledDN: number;
    paymentTerms: number;
  };
  targetInfo: {
    targetSales: number;
    amountOrdered: number;
    percentageAccomplished: number;
    fromDate: number;
    toDate: number;
  };
  trackingConsent: boolean | null;
}

export type User = WebshopUser;

export interface AuthState {
  token: {
    value: string;
    expiresAt: number;
    refreshToken?: string;
    rememberMe?: boolean;
  } | null;
  user: User | null;
}

const authStateSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    initialized: false,
    autoLogout: false,
  } as AuthState & { initialized: boolean; autoLogout: boolean },
  reducers: {
    loginUser(_state, action: PayloadAction<AuthState>) {
      return {
        token: action.payload.token,
        user: action.payload.user,
        initialized: true,
        autoLogout: false,
      };
    },
    logoutUser(
      _state,
      action: PayloadAction<{
        autoLogout?: boolean;
      }>
    ) {
      return {
        token: null,
        user: null,
        initialized: true,
        autoLogout: action.payload.autoLogout || false,
      };
    },
    updateUser(state, action: PayloadAction<User>) {
      return {
        ...state,
        user: action.payload,
      };
    },
    updateToken(state, action: PayloadAction<AuthState['token']>) {
      return {
        ...state,
        token: action.payload,
      };
    },
  },
});

export default authStateSlice;
