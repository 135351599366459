import { useAppSelector } from '@/stores';
import { useTranslation } from 'react-i18next';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAdminSettingQuery } from './misc';
import {
  kislevPatchCustomPageDetailResponse,
  kislevPatchCustomPageResponse,
} from '../customer-specific-configs/kislev/customer-specific-settings';
import { ReactNode } from 'react';
import { useUnauthenticatedAxios } from './axios';

export interface AboutUsPageContent {
  id: number;
  title: string;
  description: string;
}

export interface ContactUsPageContent {
  contactNumber: string;
  id: number;
  message: string;
  subTitle: string;
  title: string;
}

export type FaqPageContent = {
  title: string;
  faqs: Array<{
    id: number;
    question: string;
    answer: string;
  }>;
};

interface ImageResponse {
  fullpath: string;
}

export interface PageContent {
  id: number | string;
  coverImage: ImageResponse;
  sortOrder: number;
  title: string;
  shortDescription: string;
  buttonLabel: string;
}

export interface PageCarousel {
  id: number;
  title: string | null;
  description: string | null;
  image: ImageResponse;
}

export interface PageCategory {
  id: number | string;
  title: string;
  carousel: PageCarousel[];
  pageContent: Array<PageContent>;
}

export interface PageDetailContent {
  id: number | string;
  carousel: PageCarousel[];
  content: string | ReactNode;
}

export interface PageContentsQueryResponse {
  faqs: FaqPageContent;
  aboutUs: AboutUsPageContent;
  privacyPolicy: {
    dir: 'rtl' | 'ltr';
    title: string;
    content: string;
  };
  contactUs: ContactUsPageContent;
  pageCategories: PageCategory[];
}

export function usePageContentsQuery() {
  const { i18n } = useTranslation();
  const { data: adminSettings } = useAdminSettingQuery();
  const axios = useUnauthenticatedAxios();

  return useQuery<PageContentsQueryResponse>(['pageContents', i18n.language, adminSettings], {
    async queryFn() {
      if (import.meta.env.REACT_APP_CLIENT_NAME === 'kislev') {
        return kislevPatchCustomPageResponse(adminSettings!, {}, i18n.language);
      }

      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/page-contents`)
        .then((res) => res.data)
        .then((res) => {
          return {
            ...res,
            aboutUs: {
              title: 'About Us',
              ...res.aboutUs[0],
            },
            contactUs: res.contactUs[0],
          };
        });
    },
    enabled: adminSettings && adminSettings?.featureFlags.page.misc.enableCustomPage === true,
  });
}

export function usePageContentQuery(id: string, options: UseQueryOptions<PageDetailContent> = {}) {
  const { i18n } = useTranslation();
  const { data: adminSettings } = useAdminSettingQuery();
  const token = useAppSelector((state) => state.auth.token);
  const axios = useUnauthenticatedAxios();

  return useQuery<PageDetailContent>(['pageContent', id, i18n.language, adminSettings], {
    async queryFn() {
      if (import.meta.env.REACT_APP_CLIENT_NAME === 'kislev') {
        return kislevPatchCustomPageDetailResponse(adminSettings!, id, i18n.language);
      }

      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/page-content/${id}`, {
          headers: {
            Authorization: `Bearer ${token?.value}`,
          },
        })
        .then((res) => res.data)
        .then((res) => res.pageContent);
    },
    enabled: adminSettings && adminSettings?.featureFlags.page.misc.enableCustomPage === true,
    ...options,
  });
}
