import CookieConsentModal from '@/components/consent-modal';
import Footer from '@/components/footer';
import Header from '@/components/header';
import { COOKIE_CONSENT_KEY } from '@/constants';
import { useAdminSettingQuery } from '@/utils/misc';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import { useState } from 'react';
import { matchPath, Outlet, useMatches } from 'react-router-dom';
import Error404Page from './errors/404';
import { Loader } from 'tirecloud-pattern-library/dist/components/Loader/Loader';

function useIsPageEnabled() {
  const { data: adminSettings, isLoading } = useAdminSettingQuery();
  const routeMatches = useMatches();

  // admin page check
  for (const adminRule of [
    {
      url: '/admin/analytics',
      flag: adminSettings?.featureFlags.page.admin.analytics.enabled,
    },
    {
      url: '/admin/users',
      flag: adminSettings?.featureFlags.page.admin.userManagement.enabled,
    },
    {
      url: '/admin/dashboard-settings',
      flag: adminSettings?.featureFlags.page.admin.dashboardSettings.enabled,
    },
    {
      url: '/admin/email-settings',
      flag: adminSettings?.featureFlags.page.admin.emailSettings.enabled,
    },
  ]) {
    if (
      routeMatches.find((match) =>
        matchPath({ path: adminRule.url, end: false }, match.pathname)
      ) &&
      adminRule.flag !== true
    )
      return [isLoading, false];
  }

  return [isLoading, true];
}

function MainContent() {
  const [isLoading, isPageEnabled] = useIsPageEnabled();

  return isLoading ? <Loader fullScreen /> : isPageEnabled ? <Outlet /> : <Error404Page />;
}

function RootLayout() {
  const isIFrame = window !== window.parent;
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(
    localStorage.getItem(COOKIE_CONSENT_KEY) === null
  );

  if (isIFrame) {
    return <MainContent />;
  }

  return (
    <>
      <Header />
      <CookieConsentModal
        isOpen={isConsentModalOpen}
        onClose={() => {
          setIsConsentModalOpen(false);
        }}
      />
      <div className="tw-min-h-[calc(100vh-50px-92px)]">
        <MainContent />
      </div>
      <FloatButton
        type="primary"
        icon={<InfoCircleOutlined style={{ fontWeight: 'bold' }} />}
        onClick={() => {
          setIsConsentModalOpen(true);
        }}
      />
      <Footer />
    </>
  );
}

export default RootLayout;
