import { AUTH_TOKEN_KEY, COOKIE_CONSENT_KEY } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/stores';
import routerHistoryStateSlice from '@/stores/states/history';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Location, useNavigate } from 'react-router-dom';
import { match } from 'ts-pattern';
import { kislevPatchAdminSettings } from '../customer-specific-configs/kislev/customer-specific-settings';
import { useUnauthenticatedAxios } from './axios';
import featureFlags from './feature-flags';
import { v4 as uuid } from 'uuid';
import { ipcResponseMessageSchema } from './ipc';

const mockupData = {
  // upcomingMaintenance: {
  //   title: 'Scheduled Maintenance',
  //   message: 'We are currently performing scheduled maintenance. We will be back shortly.',
  //   start: dayjs().startOf('d').unix(),
  //   end: dayjs().add(1).endOf('d').unix(),
  // },
  upcomingMaintenance: null,
  featureFlags,
};

export interface DashboardSettingsType {
  cardGrid: {
    cardCount: number;
    column: number;
    responsive?: DashboardSettingsGridResponsiveConfig;
    cards: Record<number, string | undefined>;
  };
}

export const defaultDashboardSetting: DashboardSettingsType = {
  cardGrid: {
    cardCount: 6,
    column: 3,
    cards: {},
  },
};

export const SupportedBreakpoints = ['sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type DashboardSettingsGridResponsiveConfig = Partial<
  Record<(typeof SupportedBreakpoints)[number], number>
>;

export function getGridConfigFromLowestBreakpoint(
  cardGridSetting: DashboardSettingsType['cardGrid'],
  breakpoint: keyof DashboardSettingsGridResponsiveConfig | 'xs'
): number {
  if (breakpoint === 'xs') {
    return cardGridSetting.column;
  }

  const breakpointIndex = SupportedBreakpoints.findIndex((cardType) => cardType === breakpoint);
  for (let i = breakpointIndex; i >= 0; i--) {
    const breakpointValue = cardGridSetting.responsive?.[SupportedBreakpoints[i]];

    if (breakpointValue) {
      return breakpointValue;
    }
  }

  return cardGridSetting.column;
}

export function generateGridResponsiveSpan(cardGridSetting: DashboardSettingsType['cardGrid']) {
  const responsiveConfig: Partial<
    Record<'xs' | keyof DashboardSettingsGridResponsiveConfig, number>
  > = {};

  for (const bp of ['xs', ...SupportedBreakpoints]) {
    const gridConfig = getGridConfigFromLowestBreakpoint(
      cardGridSetting,
      bp as 'xs' | keyof DashboardSettingsGridResponsiveConfig
    );
    responsiveConfig[bp as 'xs' | keyof DashboardSettingsGridResponsiveConfig] = 24 / gridConfig;
  }
  return responsiveConfig;
}

export interface AdminSettings {
  logoLightVariant: {
    fullpath: string;
  } | null;
  logoDarkVariant: {
    fullpath: string;
  } | null;
  defaultProductImage: {
    fullpath: string;
  };
  videoLink: null;
  upcomingMaintenance: {
    title: string;
    message: string;
    start: number;
    end: number;
  } | null;
  loginTitle?: string;
  loginText?: string;
  featureFlags: typeof mockupData.featureFlags;
  dashboardConfiguration?: string | null;
}

export function useAdminSettingQuery() {
  const { i18n } = useTranslation();
  const axios = useUnauthenticatedAxios();

  return useQuery<AdminSettings>({
    queryKey: ['adminSetting', i18n.language],
    queryFn: async () => {
      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/admin-settings`)
        .then((res) => {
          const originalAdminSettings = {
            ...res.data.adminSettings[0],
            ...mockupData,
          };
          const resultAdminSettings = match(import.meta.env.REACT_APP_CLIENT_NAME)
            .with('kislev', () => kislevPatchAdminSettings(originalAdminSettings, res.data))
            .otherwise(() => originalAdminSettings);

          return resultAdminSettings;
        })
        .catch((err) => {
          Sentry.captureException(err);
          return Promise.reject(err);
        });
    },
    initialData: match(import.meta.env.REACT_APP_CLIENT_NAME)
      .with('kislev', () => {
        return kislevPatchAdminSettings(
          {
            logoLightVariant: {
              fullpath: '',
            },
            logoDarkVariant: {
              fullpath: '',
            },
            defaultProductImage: {
              fullpath: '',
            },
            videoLink: null,
            ...mockupData,
          },
          {
            adminSettings: [
              {
                desktopLogo: {
                  fullpath: '',
                },
                logoLightVariant: {
                  fullpath: '',
                },
                logoDarkVariant: {
                  fullpath: '',
                },
                defaultProductImage: {
                  fullpath: '',
                },
                videoLink: null,
              },
            ],
          }
        );
      })
      .otherwise(() => {
        return {
          logoLightVariant: {
            fullpath: '',
          },
          logoDarkVariant: {
            fullpath: '',
          },
          defaultProductImage: {
            fullpath: '',
          },
          videoLink: null,
          ...mockupData,
        };
      }),
  });
}

export function getUniqueObjectArrayByKey<O extends object>(arr: O[], key: keyof O) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const cookiesUsedInTheApp: Array<{
  id: string;
  required: boolean;
  cookies: Array<{
    id: string;
    cookie_names: string[];
    required: boolean;
    defaultValue?: boolean;
  }>;
}> = [
  {
    id: 'essential', // 'essential-cookies
    // name: 'Essential',
    // description:
    // 'Essential cookies are necessary for the website to function properly. This category only includes cookies that ensures basic functionalities and security features of the website. These cookies do not store any personal information.',
    required: true,
    cookies: [
      {
        id: 'auth-token',
        // name: 'Auth Token',
        // description:
        //   'Stores the user authentication token to keep the user logged in across different pages.',
        cookie_names: [AUTH_TOKEN_KEY],
        required: true,
      },
      {
        id: 'php-session',
        // name: 'PHP Session',
        // description: 'Preserves user session state across page requests.',
        cookie_names: ['PHPSESSID'],
        required: true,
      },
    ],
  },
  {
    id: 'statistics',
    // name: 'Statistics',
    // description:
    //   'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information.',
    required: false,
    cookies: [
      {
        id: 'matomo',
        // name: 'Matomo',
        // description:
        //   'Collects information about the users and their activity on the website for analytics and reporting purposes.',
        cookie_names: ['MATOMO_SESSID', '_pk_id', '_pk_ses'],
        required: false,
        defaultValue: true,
      },
    ],
  },
];

export function getConsentSetting(): { [key: string]: boolean | undefined } {
  const savedConsentSettings = localStorage.getItem(COOKIE_CONSENT_KEY);
  const cookieSettings: { [key: string]: boolean | undefined } = {};

  if (savedConsentSettings) {
    try {
      const parsedSettings = JSON.parse(savedConsentSettings);
      for (const cookieGroup of cookiesUsedInTheApp) {
        for (const subCookie of cookieGroup.cookies) {
          if (subCookie.required) {
            cookieSettings[subCookie.id] = true;
          } else if (typeof parsedSettings[subCookie.id] === 'boolean') {
            cookieSettings[subCookie.id] = parsedSettings[subCookie.id];
          }
        }
      }
    } catch (e) {
      return {};
    }
  }

  return cookieSettings;
}

export const makeNestedObjectFromPaths = (paths: (string | number)[], value: unknown): unknown => {
  if (paths.length === 0) return value;
  const [first, ...rest] = paths;
  return {
    [first]: makeNestedObjectFromPaths(rest, value),
  };
};

export function useGoBack(backButtonLink?: string) {
  const routerHistory = useAppSelector((state) => state.routerHistory);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return useCallback(() => {
    if (routerHistory.history.length === 0) {
      if (backButtonLink) {
        navigate(backButtonLink, {
          replace: true,
        });
      } else {
        navigate(-1);
      }
      return;
    }

    let currentHistoryURL: Location = routerHistory.history[routerHistory.history.length - 1];
    let i;
    for (i = routerHistory.history.length - 1; i >= 0; i--) {
      if (currentHistoryURL.pathname === routerHistory.history[i].pathname) {
        currentHistoryURL = routerHistory.history[i];
      } else {
        break;
      }
    }

    const currentIdx = routerHistory.history.length - 1;
    const destIdx = i;

    const diff = currentIdx - destIdx;

    if (diff === routerHistory.history.length) {
      if (backButtonLink) {
        navigate(backButtonLink, {
          replace: true,
        });
      } else {
        navigate(-1);
      }
      return;
    }

    // remove the diff -1 because the handler at the top will also remove one
    dispatch(routerHistoryStateSlice.actions.removeNHistory(diff - 1));
    navigate(-diff);
  }, [routerHistory.history, dispatch, navigate, backButtonLink]);
}

export function getDaysListBetween(a: Dayjs, b: Dayjs): Dayjs[] {
  const days = [];
  let current = a;
  while (current.isBefore(b)) {
    days.push(current);
    current = current.add(1, 'd');
  }
  return days;
}

export function getDayjsBetween(a: Dayjs, b: Dayjs, unit: dayjs.QUnitType): Dayjs[] {
  const days = [];
  let current = a;
  while (current.isBefore(b)) {
    days.push(current);
    current = current.add(1, unit);
  }
  return days;
}

export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function snakeCaseToTitleCase(str: string) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function parseReactNativeIPCMessage(event: MessageEvent) {
  let jsonMessage;

  try {
    jsonMessage = JSON.parse(event.data);
  } catch (e) {
    return;
  }

  // not from our webview
  if (
    !jsonMessage ||
    typeof jsonMessage !== 'object' ||
    !jsonMessage.from ||
    jsonMessage.from !== 'TIRECLOUD_RN_NATIVE'
  ) {
    return;
  }

  let result;
  try {
    result = ipcResponseMessageSchema.parse(jsonMessage);
  } catch (e) {
    Sentry.captureException(e);
    return;
  }

  return result;
}

/**
 * Download file from URL
 *
 * @param url Download URL
 * @param filename file name
 * @param extension file extension
 * @param headers request headers
 */
export function downloadFile(
  url: string,
  filename: string | null | undefined,
  extension: string,
  headers?: Record<string, string>
) {
  return new Promise<void>((resolve, reject) => {
    if (window.ReactNativeWebView) {
      const id = uuid();

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: 'DOWNLOAD_FILE',
          uuid: id,
          from: 'TIRECLOUD_RN_WEBVIEW',
          input: {
            url,
            filename,
            extension,
            headers,
          },
        })
      );

      const handleResponseMessage = (event: MessageEvent) => {
        const message = parseReactNativeIPCMessage(event);
        if (!message || message.uuid !== id) {
          return;
        }
        if (message.action !== 'DOWNLOAD_FILE') {
          return;
        }
        window.removeEventListener('message', handleResponseMessage);

        if (message.type === 'WEBVIEW_IPC_SUCCESS') {
          resolve();
        } else {
          reject(new Error(JSON.stringify(message)));
        }
      };

      window.addEventListener('message', handleResponseMessage);
      return;
    } else {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve();
    }
  });
}
