import { customerSpecificRouteMapping } from '@/customer-specific-configs/customer-specific-settings';
import { useAppSelector } from '@/stores';
import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useUnauthenticatedAxios(langOverride?: string | null) {
  const { i18n } = useTranslation();

  return useMemo(() => {
    const instance = axios.create({
      baseURL: import.meta.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    instance.interceptors.request.use((config) => {
      return {
        ...config,
        params: {
          ...config.params,
          lang: langOverride === null ? undefined : (langOverride ?? i18n.language),
        },
      };
    });

    return instance;
  }, [i18n.language, langOverride]);
}

export function useAxios() {
  const { i18n } = useTranslation();
  const token = useAppSelector((state) => state.auth.token);

  return useMemo(() => {
    const instance = axios.create({
      baseURL: import.meta.env.REACT_APP_API_URL,
      headers: {
        Authorization: `Bearer ${token?.value}`,
        'Content-Type': 'application/json',
      },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (
          error.response &&
          error.response.status === 401 &&
          // verify user endpoint should not trigger 401 event
          !((error.request?.responseURL ?? '') as string).startsWith(
            `${import.meta.env.REACT_APP_API_URL}${customerSpecificRouteMapping['/users/verify']}`
          )
        ) {
          window.dispatchEvent(new Event('app:apiCallReturns401'));
          return Promise.reject(error);
        } else {
          if (axios.isCancel(error)) {
            // custom logging logic
            return Promise.reject(error);
          }
          return Promise.reject(error);
        }
      }
    );

    instance.interceptors.request.use((config) => {
      const abortController = new AbortController();

      if (!token) {
        abortController.abort();
      }
      return {
        ...config,
        params: {
          ...config.params,
          lang: i18n.language,
        },
        signal: abortController.signal,
      };
    });

    return instance;
  }, [token, i18n.language]);
}
