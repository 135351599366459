import { match } from 'ts-pattern';
import kislevSpecificRouteMapping from './kislev/api-route-mapping';

const env = import.meta.env ?? process.env;

export const customerSpecificRouteMapping: Record<string, string> = new Proxy(
  {},
  {
    get(target, name, receiver) {
      if (!Reflect.has(target, name)) {
        return match(env.REACT_APP_CLIENT_NAME)
          .with('kislev', () => kislevSpecificRouteMapping[name as string])
          .otherwise(() => name);
      }
      return Reflect.get(target, name, receiver);
    },
    set(target, name, value, receiver) {
      return Reflect.set(target, name, value, receiver);
    },
  }
);
