import { createElement as el, StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import durationPlugin from 'dayjs/plugin/duration';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import dayjs from 'dayjs';
import 'dayjs/locale/he';
import 'dayjs/locale/de';

dayjs.extend(durationPlugin);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  environment:
    import.meta.env.MODE === 'production'
      ? import.meta.env.REACT_APP_BRANCH === 'main'
        ? 'production'
        : import.meta.env.REACT_APP_BRANCH.toUpperCase()
      : `${import.meta.env.REACT_APP_BRANCH.toUpperCase()} (${import.meta.env.MODE})`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracePropagationTargets: [],
  tracesSampleRate: 1.0,
});

createRoot(document.getElementById('root')!).render(el(StrictMode, null, el(App)));
