import { reduxStore, useAppDispatch, useAppSelector } from '@/stores';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App as AntApp, ConfigProvider, Tag } from 'antd';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import qs from 'query-string';
import { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  NavigationType,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';
import { Button, Modal } from 'tirecloud-pattern-library';
import 'tirecloud-pattern-library/dist/assets/FleetName.css';
import 'tirecloud-pattern-library/dist/assets/global.css';
import 'tirecloud-pattern-library/dist/assets/voucher.css';
import { match } from 'ts-pattern';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import './app.scss';
import RootLayout from './app/layout';
// import { MockupProvider } from './app/mockup/context';
// import MockupRootLayout from './app/mockup/layout';
import { StyleProvider } from '@ant-design/cssinjs';
import GenericErrorElement from './app/errors';
import RoleGuard from './components/auth-guard';
import { AUTH_TOKEN_KEY, DEFAULT_DOCUMENT_TITLE, REFRESH_TOKEN_THRESHOLD } from './constants';
import './i18n';
import AppUpdateModal from './pwa-update-modal';
import authStateSlice, { ALL_POSSIBLE_USER_ROLES } from './stores/states/auth';
import routerHistoryStateSlice from './stores/states/history';
import { miscStateSlice } from './stores/states/misc';
import { getConsentSetting, useAdminSettingQuery } from './utils/misc';
import {
  getUserWithAccessToken,
  useRefreshToken,
  validateAndGetUserFromStorage,
} from './utils/user';

function UserLogin(props: PropsWithChildren) {
  const { data: adminSettings } = useAdminSettingQuery();
  const dispatch = useAppDispatch();
  const firstRender = useRef(true);
  const authState = useAppSelector((state) => state.auth);
  const { t } = useTranslation();
  const { notification } = AntApp.useApp();

  const isRefreshingToken = useRef(false);
  const refreshToken = useRefreshToken();

  const attemptToRefreshToken = useCallback(() => {
    if (!Cookies.get(AUTH_TOKEN_KEY) || !adminSettings.featureFlags.page.misc.enableRefreshToken) {
      dispatch(authStateSlice.actions.loginUser({ user: null, token: null }));
      return;
    }
    // Cookies.remove(AUTH_TOKEN_KEY);
    // dispatch(authStateSlice.actions.logoutUser({ autoLogout: true }));
    if (isRefreshingToken.current) return;

    isRefreshingToken.current = true;
    refreshToken()
      .then(async (t) => {
        return getUserWithAccessToken(t.value).then((u) => {
          return { token: t, user: u };
        });
      })
      .then(({ user, token }) => {
        if (!user) {
          Cookies.remove(AUTH_TOKEN_KEY);
          dispatch(authStateSlice.actions.logoutUser({ autoLogout: true }));
        } else {
          dispatch(authStateSlice.actions.loginUser({ user, token }));
          window.location.reload();
        }
      })
      .catch(() => {
        Cookies.remove(AUTH_TOKEN_KEY);
        dispatch(authStateSlice.actions.logoutUser({ autoLogout: true }));
      })
      .finally(() => {
        isRefreshingToken.current = false;
      });
  }, [adminSettings.featureFlags.page.misc.enableRefreshToken, dispatch, refreshToken]);

  useEffect(() => {
    if (import.meta.env.DEV && firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (authState.initialized) return;

    validateAndGetUserFromStorage()
      .then((authData) => {
        if (authData.user) {
          dispatch(authStateSlice.actions.loginUser(authData));
        } else {
          attemptToRefreshToken();
        }
      })
      .catch((e) => {
        if (e instanceof AxiosError && e.response?.status === 401) {
          // don't show notification if it's 401
          attemptToRefreshToken();
        } else {
          notification.error({
            message: (
              <Trans
                i18nKey={'component.general.apiCallsFeedback.userData'}
                components={{
                  ErrorCode: <Tag className="tw-mr-1" />,
                }}
              />
            ),
            duration: 0,
          });
        }
        dispatch(authStateSlice.actions.loginUser({ token: null, user: null }));
      });
  }, [attemptToRefreshToken, authState.initialized, dispatch, notification]);

  // check the token expiration date on mount
  useEffect(() => {
    if (!authState.token) return;
    if (!authState.token.refreshToken) return;

    const checkTokenExpiration = () => {
      const tokenExpireDt = dayjs.unix(authState.token?.expiresAt ?? 0);
      const currentDt = dayjs();
      const diff = tokenExpireDt.diff(currentDt, 'milliseconds');

      if (diff <= REFRESH_TOKEN_THRESHOLD && diff > 0 && !isRefreshingToken.current) {
        isRefreshingToken.current = true;
        refreshToken().finally(() => {
          isRefreshingToken.current = false;
        });
      }
    };

    checkTokenExpiration();
  }, [authState, refreshToken]);

  useEffect(() => {
    if (!adminSettings) return;
    if (!adminSettings.featureFlags.page.misc.enableRefreshToken) return;
    if (!authState.user || !authState.token) return;
    if (authState.token.refreshToken) return;
    if (isRefreshingToken.current) return;

    isRefreshingToken.current = true;
    refreshToken().finally(() => {
      isRefreshingToken.current = false;
    });
  }, [authState, adminSettings, refreshToken]);

  useEffect(() => {
    window.addEventListener('app:apiCallReturns401', attemptToRefreshToken);
    return () => {
      window.removeEventListener('app:apiCallReturns401', attemptToRefreshToken);
    };
  }, [attemptToRefreshToken, dispatch]);

  return (
    <>
      <Modal
        open={authState.autoLogout}
        footer={null}
        onCancel={() => {
          Cookies.remove(AUTH_TOKEN_KEY);
          dispatch(authStateSlice.actions.logoutUser({ autoLogout: false }));
        }}
      >
        <h2 className="tw-text-xl">{t('page.auth.sessionExpiredModal.title')}</h2>
        <p className="tw-mb-8">{t('page.auth.sessionExpiredModal.description')}</p>
        <div className="tw-flex tw-justify-end tw-mt-4">
          <Button
            color="primary"
            onClick={() => {
              Cookies.remove(AUTH_TOKEN_KEY);
              dispatch(authStateSlice.actions.logoutUser({ autoLogout: false }));
            }}
          >
            {t('page.auth.sessionExpiredModal.loginBtn')}
          </Button>
        </div>
      </Modal>
      {props.children}
    </>
  );
}

function InnerBrowserRouter() {
  const location = useLocation();
  const { user, initialized } = useAppSelector((state) => state.auth);
  const { i18n, t } = useTranslation();
  const { data: adminSettings, isError: isAdmingSettingFetchError } = useAdminSettingQuery();
  const { notification } = AntApp.useApp();
  const isIFrame = window !== window.parent;
  const navigationType = useNavigationType();
  const dispatch = useAppDispatch();

  const titleCacheRef = useRef(DEFAULT_DOCUMENT_TITLE);
  const lastTrackedPath = useRef('');
  const consentSettings = getConsentSetting();
  const navigate = useNavigate();
  const history = useAppSelector((state) => state.routerHistory.history);

  const goBack = useCallback(() => {
    if (
      history.length === 1 &&
      // for webview, there's only two entry point (either products page or login page)
      // the rest of pages always go back to those two pages
      (location.pathname === `/products` || location.pathname === `/auth/login`)
      //  &&
      // window.webviewToNativeBridge?.closeApp !== undefined
    ) {
      // window.webviewToNativeBridge.closeApp();
      return;
    }
    navigate(-1);
  }, [history, location, navigate]);

  useEffect(() => {
    // listen app:go-back event
    window.addEventListener('app:go-back', goBack);
    return () => {
      window.removeEventListener('app:go-back', goBack);
    };
  }, [goBack]);

  const trackPageView = useCallback(() => {
    if (initialized !== true) return;
    if (window._paq === undefined) return;

    const title = document.title;
    if (title === DEFAULT_DOCUMENT_TITLE) return;

    if (consentSettings['matomo'] === true) window._paq.push(['setConsentGiven']);
    if (user) window._paq.push(['setUserId', user.userNumber]);
    window._paq.push(['deleteCustomVariables', 'page']);
    window._paq.push(['setCustomUrl', location.pathname]);
    window._paq.push(['setDocumentTitle', title]);
    window._paq.push(['trackPageView']);

    lastTrackedPath.current = location.pathname;
  }, [initialized, consentSettings, user, location.pathname]);

  useEffect(() => {
    if (navigationType === NavigationType.Push) {
      dispatch(routerHistoryStateSlice.actions.addHistory(location));
    } else if (navigationType === NavigationType.Pop) {
      dispatch(routerHistoryStateSlice.actions.popHistory());
    } else if (navigationType === NavigationType.Replace) {
      dispatch(routerHistoryStateSlice.actions.replaceHistory(location));
    }
  }, [dispatch, location, navigationType]);

  const firstRender = useRef(true);
  const haveTrackedCurrentPageURL = useRef(false);
  useEffect(() => {
    if (import.meta.env.DEV && firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!haveTrackedCurrentPageURL.current) {
      haveTrackedCurrentPageURL.current = true;
      dispatch(routerHistoryStateSlice.actions.addHistory(location));
    }
  }, [dispatch, location]);

  const isMaintenanceNotificationShown = useRef(false);

  useEffect(() => {
    if (
      !isMaintenanceNotificationShown.current &&
      adminSettings &&
      adminSettings.upcomingMaintenance &&
      adminSettings.upcomingMaintenance.start < Date.now() / 1000 &&
      adminSettings.upcomingMaintenance.end > Date.now() / 1000
    ) {
      isMaintenanceNotificationShown.current = true;
      if (!isIFrame)
        notification.open({
          message: adminSettings.upcomingMaintenance!.title,
          description: adminSettings.upcomingMaintenance!.message,
          duration: 0,
          type: 'info',
        });
    }
  }, [adminSettings, isMaintenanceNotificationShown, notification, isIFrame]);

  useEffect(() => {
    if (isAdmingSettingFetchError) {
      notification.error({
        message: (
          <Trans
            i18nKey={'component.general.apiCallsFeedback.adminSettings'}
            components={{
              ErrorCode: <Tag className="tw-mr-1" />,
            }}
          />
        ),
        duration: 0,
      });
    }
  }, [isAdmingSettingFetchError, t, notification]);

  useEffect(() => {
    const processNetworkStatus = () => {
      if (navigator.onLine) {
        dispatch(miscStateSlice.actions.setOnline());
      } else {
        dispatch(miscStateSlice.actions.setOffline());
        notification.warning({
          message: (
            <Trans
              i18nKey={'component.general.offlineDetection.title'}
              components={{
                ErrorCode: <Tag className="tw-mr-1" />,
              }}
            />
          ),
          description: t('component.general.offlineDetection.description'),
          duration: 10,
          showProgress: true,
          pauseOnHover: true,
        });
      }
    };
    window.addEventListener('offline', processNetworkStatus);
    window.addEventListener('online', processNetworkStatus);
    window.addEventListener('load', processNetworkStatus);

    return () => {
      window.removeEventListener('offline', processNetworkStatus);
      window.removeEventListener('online', processNetworkStatus);
      window.removeEventListener('load', processNetworkStatus);
    };
  }, [dispatch, notification, t]);

  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        objectToSearchString: qs.stringify,
        searchStringToObject: qs.parse,
      }}
    >
      <Helmet
        defaultTitle={DEFAULT_DOCUMENT_TITLE}
        titleTemplate={`%s - ${DEFAULT_DOCUMENT_TITLE}`}
        defer={false}
        onChangeClientState={(newState) => {
          if (
            newState.title !== titleCacheRef.current &&
            lastTrackedPath.current !== location.pathname &&
            !location.pathname.startsWith('/mockup')
          ) {
            titleCacheRef.current = newState.title;
            trackPageView();
          }
        }}
      >
        <html lang={i18n.language} dir={i18n.dir(i18n.language)} />
      </Helmet>
      <Outlet />
    </QueryParamProvider>
  );
}

const Router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  [
    {
      element: <InnerBrowserRouter />,
      errorElement: <GenericErrorElement />,
      children: [
        {
          element: (
            <RoleGuard requiredRoles={ALL_POSSIBLE_USER_ROLES.filter((role) => role !== 'GUEST')}>
              <RootLayout />
            </RoleGuard>
          ),
          children: [
            {
              index: true,
              // element: <HomePage />,
              async lazy() {
                const HomePage = await import('./app/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: HomePage.default };
              },
            },
          ],
        },
        // webshop - part of webshop
        {
          element: (
            <RoleGuard requiredRoles={['ROLE_WEBSHOP_USER']}>
              <RootLayout />
            </RoleGuard>
          ),
          children: [
            {
              path: 'products',
              // element: <ProductPage />,
              async lazy() {
                const ProductPage = await import('./app/products/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: ProductPage.default };
              },
            },
            {
              path: 'cart',
              // element: <CartPage />,
              async lazy() {
                const CartPage = await import('./app/cart/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: CartPage.default };
              },
            },
            {
              path: 'orders',
              // element: <OrderPage />,
              async lazy() {
                const OrderPage = await import('./app/order/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: OrderPage.default };
              },
            },
            {
              path: 'reserved-stocks',
              // element: <ReservedStockPage />,
              async lazy() {
                const ReservedStockPage = await import('./app/reserved-stocks/page').catch(
                  (error) => {
                    Sentry.captureException(error);
                    return Promise.reject(error);
                  }
                );
                return { Component: ReservedStockPage.default };
              },
            },
            {
              path: 'notifications',
              // element: <NotificationPage />,
              async lazy() {
                const NotificationPage = await import('./app/notification/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: NotificationPage.default };
              },
            },
            {
              path: 'dashboard',
              // element: <DashboardPage />,
              async lazy() {
                const DashboardPage = await import('./app/dashboard/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: DashboardPage.default };
              },
            },
          ],
        },
        // webshop/admin user profile part of webshop
        {
          element: (
            <RoleGuard requiredRoles={['ROLE_ADMIN', 'ROLE_WEBSHOP_USER']}>
              <RootLayout />
            </RoleGuard>
          ),
          children: [
            {
              path: 'user',
              // element: <UserLayout />,
              async lazy() {
                const UserLayout = await import('./app/user/layout').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: UserLayout.default };
              },
              children: [
                {
                  path: 'profile',
                  // element: <ProfilePage />,
                  async lazy() {
                    const ProfilePage = await import('./app/user/profile/page').catch((error) => {
                      Sentry.captureException(error);
                      return Promise.reject(error);
                    });
                    return { Component: ProfilePage.default };
                  },
                },
                {
                  path: 'security',
                  // element: <SecurityPage />,
                  async lazy() {
                    const SecurityPage = await import('./app/user/security/page').catch((error) => {
                      Sentry.captureException(error);
                      return Promise.reject(error);
                    });
                    return { Component: SecurityPage.default };
                  },
                },
                {
                  path: 'complaints',
                  // element: <ComplaintsPage />,
                  async lazy() {
                    const ComplaintsPage = await import('./app/user/complaint/page').catch(
                      (error) => {
                        Sentry.captureException(error);
                        return Promise.reject(error);
                      }
                    );
                    return { Component: ComplaintsPage.default };
                  },
                },
                {
                  path: 'accounting',
                  // element: <AccountingPage />,
                  async lazy() {
                    const AccountingPage = await import('./app/user/accounting/page').catch(
                      (error) => {
                        Sentry.captureException(error);
                        return Promise.reject(error);
                      }
                    );
                    return { Component: AccountingPage.default };
                  },
                },
                {
                  path: 'notification-settings',
                  // element: <NotificationSettings />,
                  async lazy() {
                    const NotificationSettings = await import(
                      './app/user/notification-settings/page'
                    ).catch((error) => {
                      Sentry.captureException(error);
                      return Promise.reject(error);
                    });
                    return { Component: NotificationSettings.default };
                  },
                },
              ],
            },
          ],
        },
        // admin route - part of webshop
        {
          path: 'admin',
          element: (
            <RoleGuard requiredRoles={['ROLE_ADMIN']}>
              <RootLayout />
            </RoleGuard>
          ),
          children: [
            {
              // element: <AdminLayout />,
              lazy: async () => {
                const AdminLayout = await import('./app/admin/layout').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: AdminLayout.default };
              },
              children: [
                {
                  index: true,
                  // element: <AdminHomePage />,
                  async lazy() {
                    const AdminHomePage = await import('./app/admin/page').catch((error) => {
                      Sentry.captureException(error);
                      return Promise.reject(error);
                    });
                    return { Component: AdminHomePage.default };
                  },
                },
                {
                  path: 'analytics',
                  children: [
                    {
                      // element: <AnalyticsPage />,
                      index: true,
                      async lazy() {
                        const AnalyticsPage = await import('./app/admin/analytics/page').catch(
                          (error) => {
                            Sentry.captureException(error);
                            return Promise.reject(error);
                          }
                        );
                        return { Component: AnalyticsPage.default };
                      },
                    },
                    {
                      path: 'user',
                      children: [
                        {
                          index: true,
                          // element: <AnalyticsUserPage />,
                          async lazy() {
                            const AnalyticsUserPage = await import(
                              './app/admin/analytics/user/page'
                            ).catch((error) => {
                              Sentry.captureException(error);
                              return Promise.reject(error);
                            });
                            return { Component: AnalyticsUserPage.default };
                          },
                        },
                        {
                          path: ':id',
                          async lazy() {
                            const AnalyticsUserDetailsPage = await import(
                              './app/admin/analytics/user/[id]/page'
                            ).catch((error) => {
                              Sentry.captureException(error);
                              return Promise.reject(error);
                            });
                            return { Component: AnalyticsUserDetailsPage.default };
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'users',
                  // element: <UserPage />,
                  async lazy() {
                    const UserPage = await import('./app/admin/users/page').catch((error) => {
                      Sentry.captureException(error);
                      return Promise.reject(error);
                    });
                    return { Component: UserPage.default };
                  },
                },
                {
                  path: 'dashboard-settings',
                  // element: <DashboardSettingPage />,
                  async lazy() {
                    const DashboardSettingPage = await import(
                      './app/admin/dashboard-setting/page'
                    ).catch((error) => {
                      Sentry.captureException(error);
                      return Promise.reject(error);
                    });
                    return { Component: DashboardSettingPage.default };
                  },
                },
                {
                  path: 'email-settings',
                  // element: <EmailRecipientSettingPage />,
                  async lazy() {
                    const EmailRecipientSettingPage = await import(
                      './app/admin/email-settings/page'
                    ).catch((error) => {
                      Sentry.captureException(error);
                      return Promise.reject(error);
                    });
                    return { Component: EmailRecipientSettingPage.default };
                  },
                },
              ],
            },
          ],
        },
        // auth routes
        {
          path: '/auth',
          element: (
            <RoleGuard requiredRoles={['GUEST']}>
              <RootLayout />
            </RoleGuard>
          ),
          children: [
            {
              path: 'login',
              // don't lazy load page since it's critial
              // element: <LoginPage />,
              async lazy() {
                const LoginPage = await import('./app/auth/login/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: LoginPage.default };
              },
            },
            {
              path: 'register',
              // element: <RegisterPage />,
              async lazy() {
                const RegisterPage = await import('./app/auth/register/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: RegisterPage.default };
              },
            },
            {
              path: 'register/success',
              // element: <RegisterSuccessPage />,
              async lazy() {
                const RegisterSuccessPage = await import('./app/auth/register/success').catch(
                  (error) => {
                    Sentry.captureException(error);
                    return Promise.reject(error);
                  }
                );
                return { Component: RegisterSuccessPage.default };
              },
            },
            {
              path: 'onboarding/:token',
              // element: <OnboardingPage />,
              async lazy() {
                const OnboardingPage = await import('./app/auth/onboarding/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: OnboardingPage.default };
              },
            },
            {
              path: 'reset-password/:token',
              // element: <ResetPasswordPage />,
              async lazy() {
                const ResetPasswordPage = await import('./app/auth/reset-password/page').catch(
                  (error) => {
                    Sentry.captureException(error);
                    return Promise.reject(error);
                  }
                );
                return { Component: ResetPasswordPage.default };
              },
            },
          ],
        },
        // other routes
        {
          path: '/brand',
          element: (
            <RoleGuard requiredRoles={ALL_POSSIBLE_USER_ROLES}>
              <RootLayout />
            </RoleGuard>
          ),
          children: [
            {
              index: true,
              // element: <BrandPage />,
              async lazy() {
                const BrandPage = await import('./app/brand/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: BrandPage.default };
              },
            },
            {
              path: ':id',
              async lazy() {
                const CustomPage = await import('./app/brand/[id]/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: CustomPage.default };
              },
            },
          ],
        },
        // {
        //   path: 'mockup',
        //   element: (
        //     <RoleGuard requiredRoles={['ROLE_WEBSHOP_USER']}>
        //       <MockupProvider>
        //         <MockupRootLayout />
        //       </MockupProvider>
        //     </RoleGuard>
        //   ),
        //   children: [
        //     {
        //       path: 'products',
        //       // element: <MockupProductPage />,
        //       async lazy() {
        //         const MockupProductPage = await import('./app/mockup/products/page').catch(
        //           (error) => {
        //             Sentry.captureException(error);
        //             return Promise.reject(error);
        //           }
        //         );
        //         return { Component: MockupProductPage.default };
        //       },
        //     },
        //     {
        //       path: 'cart',
        //       // element: <MockupCartPage />,
        //       async lazy() {
        //         const MockupCartPage = await import('./app/mockup/cart/page').catch((error) => {
        //           Sentry.captureException(error);
        //           return Promise.reject(error);
        //         });
        //         return { Component: MockupCartPage.default };
        //       },
        //     },
        //   ],
        // },
        {
          path: '*',
          element: (
            <RoleGuard requiredRoles={ALL_POSSIBLE_USER_ROLES}>
              <RootLayout />
            </RoleGuard>
          ),
          children: [
            {
              path: '*',
              async lazy() {
                const CustomPage = await import('./app/custom-page/page').catch((error) => {
                  Sentry.captureException(error);
                  return Promise.reject(error);
                });
                return { Component: CustomPage.default };
              },
            },
          ],
        },
      ],
    },
  ],
  {
    basename: import.meta.env.REACT_APP_PREFIX,
  }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: 'always',
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const loader = document.querySelector('.loader-in-shell');
    if (loader) loader.remove();
  }, []);

  useEffect(() => {
    dayjs.updateLocale(i18n.language, {
      weekStart: match(i18n.language)
        .with('he', () => 0)
        .otherwise(() => 1),
    });
  }, [i18n.language]);

  return (
    <Sentry.ErrorBoundary>
      <Provider store={reduxStore}>
        <ConfigProvider
          theme={{
            token: {
              // fontFamily: 'Calibri, sans-serif',
              // fontSize: 16,
              colorPrimary: 'rgba(250, 176, 5, 1)',
              colorPrimaryHover: '#5a6268',
              colorLink: 'rgba(20, 16, 5, 1)',
              screenXSMin: 375,
              screenXS: 375,
              screenXSMax: 574,
              screenSMMin: 575,
              screenSM: 575,
              screenSMMax: 767,
              screenMDMin: 768,
              screenMD: 768,
              screenMDMax: 1023,
              screenLGMin: 1024,
              screenLG: 1024,
              screenLGMax: 1439,
              screenXLMin: 1440,
              screenXL: 1440,
              screenXLMax: 1599,
              screenXXLMin: 1600,
              screenXXL: 1600,
            },
            components: {
              Menu: {
                itemBg: 'transparent',
                itemColor: 'white',
                itemHoverColor: 'black',
                itemHoverBg: 'var(--primary-color)',
                itemActiveBg: 'var(--primary-color)',
                itemSelectedBg: 'var(--primary-color)',
                itemSelectedColor: 'black',
                itemBorderRadius: 0,
                itemMarginInline: 0,
                itemPaddingInline: 28,
                itemMarginBlock: 0,
                iconMarginInlineEnd: 24,
              },
            },
          }}
          direction={i18n.dir()}
        >
          <StyleProvider hashPriority="low">
            <AntApp
              notification={{
                duration: 10,
                rtl: i18n.dir() === 'rtl',
                placement: i18n.dir() === 'rtl' ? 'topLeft' : 'topRight',
                showProgress: true,
              }}
            >
              <AppUpdateModal />
              <QueryClientProvider client={queryClient}>
                <UserLogin>
                  <RouterProvider router={Router} />
                </UserLogin>
              </QueryClientProvider>
            </AntApp>
          </StyleProvider>
        </ConfigProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
