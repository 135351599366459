import { configureStore } from '@reduxjs/toolkit';
import authStateSlice from './states/auth';
import { TypedUseSelectorHook, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import routerHistoryStateSlice from './states/history';
import { miscStateSlice } from './states/misc';

export const reduxStore = configureStore({
  reducer: {
    [authStateSlice.name]: authStateSlice.reducer,
    [routerHistoryStateSlice.name]: routerHistoryStateSlice.reducer,
    [miscStateSlice.name]: miscStateSlice.reducer,
  },
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat([Sentry.createReduxEnhancer({})]),
});

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
