import { Link } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import css from './404.module.scss';

function Error404Page() {
  return (
    <main className="page-content">
      <div className={css['page-not-found']}>
        <div className={css.inner}>
          <h1>
            4<span>0</span>4
          </h1>
          <h3>
            The page you are looking for might have been removed had its name changed or is
            temporarily unavailable.
          </h3>
          <Link to={'/products'}>
            <Button>{'Products page'}</Button>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Error404Page;
