import { NullableShippingAddress, ShippingAddress } from '@/components/checkout-modal/common';
import { AUTH_TOKEN_KEY } from '@/constants';
import { customerSpecificRouteMapping } from '@/customer-specific-configs/customer-specific-settings';
import { useAppDispatch, useAppSelector } from '@/stores';
import authStateSlice, { AuthState, User, UserUpdatePayload } from '@/stores/states/auth';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { boolean, is, number, object, optional, string } from 'superstruct';
import { useAxios, useUnauthenticatedAxios } from './axios';
import { useCallback } from 'react';
import axios from 'axios';
import { useAdminSettingQuery } from './misc';

function parseAndValidateAccessToken(auth_data: string): AuthState['token'] | null {
  try {
    const mayBeValidAuthData = JSON.parse(auth_data);
    const schema = object({
      value: string(),
      expiresAt: number(),
      refreshToken: optional(string()),
      rememberMe: optional(boolean()),
    });

    if (is(mayBeValidAuthData, schema)) {
      if (dayjs.unix(mayBeValidAuthData.expiresAt).isBefore(dayjs())) return null;
      return mayBeValidAuthData;
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function getUserObjectFromRawUser(userObj: any): User {
  const user = userObj;

  return {
    id: user.id,
    username: user.username,
    userNumber: user.userNumber,
    __role: user.role || 'ROLE_WEBSHOP_USER',
    published: user.published ?? true,
    about: {
      fullName: user.fullName,
      jobTitle: user.jobTitle,
      company: user.Company,
    },
    contactInfo: {
      email: user.email,
      phoneNumber: user.phoneNumber,
      defaultCommunication: user.defaultCommunication,
    },
    shippingAddress: {
      address: user.address,
      city: user.city,
      state: user.state,
      zipCode: user.zipcode,
      country: user.country,
    },
    credit: {
      currencyType: user.currencyType,
      lineObligo: user.lineObligo ?? 0,
      utilizedObligo: user.utilizedObligo ?? 0,
      unutilizedObligo: user.unutilizedObligo ?? 0,
      deferredChequeTotal: user.deferredChequeTotal ?? 0,
      openOrderValue: user.openOrderValue ?? 0,
      openDebt: user.openDebt ?? 0,
      unbilledDN: user.unbilledDN ?? 0,
      paymentTerms: user.paymentTerms ?? 120,
    },
    targetInfo: {
      targetSales: user.targetSales ?? 0,
      amountOrdered: user.amountOrdered ?? 0,
      percentageAccomplished: user.percentageAccomplished ?? 0,
      fromDate: user.fromDate ?? 0,
      toDate: user.toDate ?? 0,
    },
    trackingConsent: user.trackingConsent,
  };
}

export function useRefreshToken() {
  const token = useAppSelector((state) => state.auth.token);
  const axios = useUnauthenticatedAxios();
  const dispatch = useAppDispatch();

  const getRefreshToken = useCallback(
    async (isTokenStillValid: boolean) => {
      if (token?.refreshToken) return token.refreshToken;
      if (isTokenStillValid)
        return axios.get('/api/v1/refresh-token').then((res) => res.data.refreshToken);

      const auth_data = Cookies.get(AUTH_TOKEN_KEY);
      if (!auth_data) return null;

      try {
        const mayBeValidAuthData = JSON.parse(auth_data);
        const schema = object({
          value: string(),
          expiresAt: number(),
          refreshToken: optional(string()),
          rememberMe: optional(boolean()),
        });

        if (is(mayBeValidAuthData, schema)) {
          return mayBeValidAuthData.refreshToken;
        }
        return null;
      } catch (e) {
        return null;
      }
    },
    [axios, token]
  );

  return useCallback(
    async (isTokenStillValid: boolean = false) => {
      const refreshToken = await getRefreshToken(isTokenStillValid);
      if (!refreshToken) return Promise.reject('No refresh token found');

      return axios
        .post('/api/v1/access-token/refresh', {
          refreshToken: refreshToken,
        })
        .then((res) => {
          const t = {
            value: res.data.accessToken,
            expiresAt: dayjs().add(res.data.accessTokenExpiresIn, 'second').unix(),
            refreshToken: res.data.refreshToken,
            rememberMe: true,
          };
          Cookies.set(AUTH_TOKEN_KEY, JSON.stringify(t), {
            expires: dayjs.unix(t.expiresAt).toDate(),
          });
          dispatch(authStateSlice.actions.updateToken(t));
          return Promise.resolve(t);
        })
        .catch((err) => {
          Sentry.captureException(err);
          return Promise.reject(err);
        });
    },
    [getRefreshToken, axios, dispatch]
  );
}

export async function getUserWithAccessToken(token: string): Promise<User> {
  return axios
    .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return getUserObjectFromRawUser(res.data);
    })
    .catch((err) => {
      Sentry.captureException(err);
      return Promise.reject(err);
    });
}

export async function validateAndGetUserFromStorage(): Promise<AuthState> {
  const auth_data = Cookies.get(AUTH_TOKEN_KEY);

  if (!auth_data)
    return Promise.resolve({
      token: null,
      user: null,
    });

  const maybeToken = parseAndValidateAccessToken(auth_data);
  if (!maybeToken)
    return Promise.resolve({
      token: null,
      user: null,
    });

  return getUserWithAccessToken(maybeToken.value).then((user: User) => {
    if (!user)
      return {
        token: null,
        user: null,
      } satisfies AuthState;

    return {
      token: {
        value: maybeToken.value,
        expiresAt: maybeToken.expiresAt,
        refreshToken: maybeToken.refreshToken,
        rememberMe: maybeToken.rememberMe,
      },
      user,
    } satisfies AuthState;
  });
}

export function useUserShippingAddresses({
  enabled = true,
}: UseQueryOptions<{
  userDefaultAddress: NullableShippingAddress;
  lastUsedShippingAddress: ShippingAddress[];
}> = {}) {
  const token = useAppSelector((state) => state.auth.token);
  const axios = useAxios();

  return useQuery<{
    userDefaultAddress: NullableShippingAddress;
    lastUsedShippingAddress: ShippingAddress[];
  }>({
    queryKey: ['userShippingAddresses'],
    queryFn: async () => {
      return axios
        .get(
          `${import.meta.env.REACT_APP_API_URL}${customerSpecificRouteMapping['/users/shipping-addresses']}`
        )
        .then((res) => res.data)
        .catch((err) => {
          Sentry.captureException(err);
          return Promise.reject(err);
        });
    },
    enabled: !!token && enabled,
  });
}

export function useUpdateUserMutation() {
  const token = useAppSelector((state) => state.auth.token);
  const client = useQueryClient();
  const dispatch = useAppDispatch();
  const axios = useAxios();

  return useMutation({
    mutationKey: ['user'],
    mutationFn: async ({ id, ...payload }: UserUpdatePayload) => {
      return axios
        .patch(`${import.meta.env.REACT_APP_API_URL}/api/v1/user/${id}`, payload)
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    onSuccess: async () => {
      client.invalidateQueries(['user']);
      return getUserWithAccessToken(token!.value).then((payload) => {
        dispatch(authStateSlice.actions.updateUser(payload));
      });
    },
  });
}
