import { createSlice } from '@reduxjs/toolkit';

export const miscStateSlice = createSlice({
  name: 'misc',
  initialState: {
    isOffline: false,
  },
  reducers: {
    setOffline: (state) => {
      state.isOffline = true;
    },
    setOnline: (state) => {
      state.isOffline = false;
    },
  },
});
