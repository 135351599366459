import { useAxios } from '@/utils/axios';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { App, AutoComplete, Input, Tag } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import { IconSearch } from 'tirecloud-pattern-library/dist/components/Icons/IconSearch';
import { StringParam, useQueryParam } from 'use-query-params';

function Search({ isMobileSearch = false }: { isMobileSearch?: boolean }) {
  const { t } = useTranslation();
  const [searchQuery] = useQueryParam('q', StringParam);
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
  const navigate = useNavigate();
  const [searchHistory, setSearchHistory] = useState<string[]>([]);

  const [autocompleteOptions, setAutoCompleteOptions] = useState<
    Array<{
      value: string;
    }>
  >([]);
  const axios = useAxios();
  const { notification } = App.useApp();

  const { data: matchCodes, isError } = useQuery<string[]>({
    queryKey: ['matchCodes'],
    queryFn: async () => {
      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/tire-sizes/match-codes`)
        .then((res) => res.data)
        .then((res) => {
          return res.matchCodes;
        })
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
  });

  useEffect(() => {
    if (isError) {
      notification.error({
        key: 'APP004',
        message: (
          <Trans
            i18nKey={'component.search.matchCodeFetchError'}
            components={{
              ErrorCode: <Tag className="tw-mr-1" />,
            }}
          />
        ),
        duration: 0,
      });
    }
  }, [isError, notification]);

  useEffect(() => {
    if (!searchQuery) {
      setSearchKeyword(null);
    }
  }, [searchQuery]);

  const updateSuggestions = useCallback(
    (value: string) => {
      if (!matchCodes) return;

      const regex = /^[0-9]+$/;
      let suggestions: typeof autocompleteOptions = [];

      if (value.match(regex) && value.length >= 1) {
        const filterData = matchCodes.filter((item) => {
          return item.includes(value);
        });
        const sortData = filterData;
        const match = sortData;

        const filtered = sortData.filter((item, index) => {
          return !match.includes(item, index + 1);
        });
        suggestions = filtered.map((item) => {
          return {
            value: item,
          };
        });
      }
      setAutoCompleteOptions(suggestions.slice(0, 5));
    },
    [matchCodes]
  );

  const applySearch = useCallback(
    (searchStr: string) => {
      navigate(`/products?q=${searchStr}`);
      setSearchHistory((prev) => {
        const newHistory = prev.filter((item) => item !== searchStr);
        newHistory.unshift(searchStr);
        return newHistory;
      });
      setAutoCompleteOptions([]);
    },
    [navigate]
  );

  const location = useLocation();

  const autocompOption = useMemo(() => {
    return [
      {
        label: 'Suggestions',
        options:
          autocompleteOptions.length > 0
            ? autocompleteOptions
            : [{ value: 'no suggestion', disabled: true }],
      },
      ...(searchHistory.length > 0
        ? [
            {
              label: 'Recent Searches',
              options: searchHistory.slice(0, 5).map((item) => {
                return {
                  value: item,
                };
              }),
            },
          ]
        : []),
    ];
  }, [autocompleteOptions, searchHistory]);

  const mobileSearchClassName = useMemo(() => {
    if (location.pathname.startsWith('/brand') || location.pathname === '/about') {
      return 'tw-hidden';
    }
    return undefined;
  }, [location.pathname]);

  return (
    <div
      className={
        isMobileSearch ? mobileSearchClassName : 'tw-h-[60px] tw-max-w-[400px] !tw-flex-grow'
      }
      {...(isMobileSearch ? { id: 'main-mobile-searchbox' } : { id: 'main-header-searchbox' })}
    >
      <AutoComplete
        className="tw-w-full"
        options={autocompOption}
        onSearch={updateSuggestions}
        onSelect={(searchStr) => {
          setSearchKeyword(searchStr);
          applySearch(searchStr);
          if (window._paq) {
            window._paq.push(['trackEvent', 'Search', 'Click Search Suggestion', searchStr]);
          }
        }}
        listHeight={800}
        value={searchKeyword === null ? searchQuery ?? '' : searchKeyword}
      >
        <div className={isMobileSearch ? 'mobile-search-sm' : 'top-search-box'}>
          <Input
            type="search"
            placeholder={t('component.search.placeholder')}
            rootClassName={isMobileSearch ? undefined : '!tw-placeholder-[var(--shade-200)]'}
            value={searchKeyword === null ? searchQuery ?? '' : searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            onPressEnter={() => {
              applySearch(searchKeyword ?? '');
            }}
            data-test-id="header-search-input"
          />
          <Button
            type="submit"
            className="btn-icon submit-search"
            icon={<IconSearch />}
            onClick={() => {
              applySearch(searchKeyword ?? '');
            }}
          />
        </div>
      </AutoComplete>
    </div>
  );
}

export default Search;
