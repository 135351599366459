import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BarChartOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { IconGear } from 'tirecloud-pattern-library/dist/components/Icons/IconGear';
import { AdminSettings } from '@/utils/misc';

export function getAdminSideMenu({
  navigate,
  t,
  firstChildBorderTop = false,
  adminSettings,
}: {
  navigate: ReturnType<typeof useNavigate>;
  t: ReturnType<typeof useTranslation>['t'];
  firstChildBorderTop?: boolean;
  adminSettings?: AdminSettings;
}) {
  return [
    {
      key: '/admin/analytics',
      label: t('component.header.adminMenu.analytics'),
      icon: <BarChartOutlined />,
      className: classNames(
        'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-7',
        firstChildBorderTop ? 'tw-border-t' : ''
      ),
      onClick: () => {
        navigate('/admin/analytics');
      },
      enabled: adminSettings?.featureFlags.page.admin.analytics.enabled,
    },
    {
      key: '/admin/analytics/user',
      label: t('component.header.adminMenu.userBehavior'),
      icon: <BarChartOutlined />,
      className: classNames(
        'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-7'
      ),
      onClick: () => {
        navigate('/admin/analytics/user');
      },
      enabled: adminSettings?.featureFlags.page.admin.analytics.enabled,
    },
    {
      key: '/admin/users',
      label: t('component.header.adminMenu.users'),
      icon: <UserOutlined />,
      className: classNames(
        'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-7'
      ),
      onClick: () => {
        navigate('/admin/users');
      },
      enabled: adminSettings?.featureFlags.page.admin.userManagement.enabled,
    },
    {
      key: '/admin/dashboard-settings',
      label: t('component.header.adminMenu.dashboardSettings'),
      icon: (
        <span>
          <IconGear size={14} />
        </span>
      ),
      className: classNames(
        'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-7'
      ),
      onClick: () => {
        navigate('/admin/dashboard-settings');
      },
      enabled: adminSettings?.featureFlags.page.admin.dashboardSettings.enabled,
    },
    {
      key: '/admin/email-settings',
      label: t('component.header.adminMenu.emailSettings'),
      icon: (
        <span>
          <MailOutlined size={14} />
        </span>
      ),
      className: classNames(
        'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-7'
      ),
      onClick: () => {
        navigate('/admin/email-settings');
      },
      enabled: adminSettings?.featureFlags.page.admin.emailSettings.enabled,
    },
  ].filter((item) => item.enabled);
}
